import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="prestige-classes"></a><h2>Prestige Classes</h2>
    <a id="assassin"></a><h3>ASSASSIN</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p className="initial"><a href="http://www.wizards.com/dnd/images/dmg35_gallery/DMG35_PG180_WEB.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
          "border": "0px solid",
          "width": "73px",
          "height": "75px"
        }} /></a><b>Hit Die</b>:
d6.</p>
    <a id="assassin-requirements"></a><h6>Requirements</h6>
    <p className="initial">To qualify to become an assassin, a character
must fulfill all the following criteria.</p>
    <p><b>Alignment</b>: Any evil.</p>
    <p><b>Skills</b>: Disguise 4 ranks, Hide 8 ranks, Move Silently 8
ranks.</p>
    <p><b>Special</b>: The character must kill someone for no other
reason than to join the assassins.</p>
    <h6>Class Skills</h6>
    <p className="initial">The assassin's class skills (and the key
ability for each skill) are <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#balance">Balance</a>
(Dex), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#bluff">Bluff</a>
(Cha), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#climb">Climb</a>
(Str), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#craft">Craft</a>
(Int), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#decipher-script">Decipher Script</a> (Int), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#diplomacy">Diplomacy</a>
(Cha), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#disable-device">Disable Device</a> (Int), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#disguise">Disguise</a>
(Cha), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#escape-artist">Escape Artist</a> (Dex), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#forgery">Forgery</a>
(Int), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#gather-information">Gather Information</a> (Cha),
      <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#hide">Hide</a>
(Dex), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#intimidate">Intimidate</a> (Cha), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#jump">Jump</a>
(Str), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#listen">Listen</a> (Wis), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#move-silently">Move
Silently</a> (Dex), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#open-lock">Open Lock</a> (Dex), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#search">Search</a>
(Int), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#sense-motive">Sense Motive</a> (Wis), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#sleight-of-hand">Sleight
of Hand</a> (Dex), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#spot">Spot</a> (Wis), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#swim">Swim</a>
(Str), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#tumble">Tumble</a> (Dex), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#use-magic-device">Use Magic Device</a> (Cha), and
      <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#use-rope">Use Rope</a> (Dex). </p>
    <p><b>Skill Points at Each Level</b>: 4 + Int modifier.</p>
    <a id="table-the-assassin"></a><p><b>Table: The Assassin</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr valign="bottom">
          <th rowSpan="2" style={{
            "width": "6%"
          }}>Level</th>
          <th rowSpan="2" style={{
            "width": "12%"
          }}>Base Attack Bonus</th>
          <th rowSpan="2" style={{
            "width": "6%"
          }}>Fort Save</th>
          <th rowSpan="2" style={{
            "width": "6%"
          }}>Ref Save</th>
          <th rowSpan="2" style={{
            "width": "6%"
          }}>Will Save</th>
          <th rowSpan="2" style={{
            "width": "40%"
          }}>Special</th>
          <th colSpan="4" style={{
            "textAlign": "center"
          }}>Spells per Day</th>
        </tr>
        <tr>
          <th style={{
            "width": "6%"
          }}>1st</th>
          <th style={{
            "width": "6%"
          }}>2nd</th>
          <th style={{
            "width": "6%"
          }}>3rd</th>
          <th style={{
            "width": "6%"
          }}>4th</th>
        </tr>
        <tr className="odd-row">
          <td>1st</td>
          <td>+0</td>
          <td>+0</td>
          <td>+2</td>
          <td>+0</td>
          <td>Sneak attack +1d6, death attack, poison use, spells</td>
          <td>0</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>2nd</td>
          <td>+1</td>
          <td>+0</td>
          <td>+3</td>
          <td>+0</td>
          <td>+1 save against poison, uncanny dodge</td>
          <td>1</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>3rd</td>
          <td>+2</td>
          <td>+1</td>
          <td>+3</td>
          <td>+1</td>
          <td>Sneak attack +2d6</td>
          <td>2</td>
          <td>0</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>4th</td>
          <td>+3</td>
          <td>+1</td>
          <td>+4</td>
          <td>+1</td>
          <td>+2 save against poison</td>
          <td>3</td>
          <td>1</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>5th</td>
          <td>+3</td>
          <td>+1</td>
          <td>+4</td>
          <td>+1</td>
          <td>Improved uncanny dodge, sneak attack +3d6</td>
          <td>3</td>
          <td>2</td>
          <td>0</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>6th</td>
          <td>+4</td>
          <td>+2</td>
          <td>+5</td>
          <td>+2</td>
          <td>+3 save against poison</td>
          <td>3</td>
          <td>3</td>
          <td>1</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>7th</td>
          <td>+5</td>
          <td>+2</td>
          <td>+5</td>
          <td>+2</td>
          <td>Sneak attack +4d6</td>
          <td>3</td>
          <td>3</td>
          <td>2</td>
          <td>0</td>
        </tr>
        <tr>
          <td>8th</td>
          <td>+6</td>
          <td>+2</td>
          <td>+6</td>
          <td>+2</td>
          <td>+4 save against poison, hide in plain sight</td>
          <td>3</td>
          <td>3</td>
          <td>3</td>
          <td>1</td>
        </tr>
        <tr className="odd-row">
          <td>9th</td>
          <td>+6</td>
          <td>+3</td>
          <td>+6</td>
          <td>+3</td>
          <td>Sneak attack +5d6</td>
          <td>3</td>
          <td>3</td>
          <td>3</td>
          <td>2</td>
        </tr>
        <tr>
          <td className="last-row">10th</td>
          <td className="last-row">+7</td>
          <td className="last-row">+3</td>
          <td className="last-row">+7</td>
          <td className="last-row">+3</td>
          <td className="last-row">+5 save against poison</td>
          <td className="last-row">3</td>
          <td className="last-row">3</td>
          <td className="last-row">3</td>
          <td className="last-row">3</td>
        </tr>
      </tbody>
    </table>
    <a id="assassin-class-features"></a><h6>Class Features</h6>
    <p className="initial">All of the following are Class Features of the
assassin prestige class.</p>
    <p><b>Weapon and Armor Proficiency</b>: Assassins are proficient
with the crossbow (hand, light, or heavy), dagger (any type), dart,
rapier, sap, shortbow (normal and composite), and short sword.
Assassins are proficient with light armor but not with shields.</p>
    <p><b>Sneak Attack</b>: This is exactly like the rogue ability of
the same name. The extra damage dealt increases by +1d6 every other
level (2nd, 4th, 6th, 8th, and 10th). If an assassin gets a sneak
attack bonus from another source the bonuses on damage stack.</p>
    <a id="assassin-death-attack"></a><p><b>Death Attack</b>: If an assassin studies his victim for 3
rounds and then makes a sneak attack with a melee weapon that
successfully deals damage, the sneak attack has the additional effect
of possibly either paralyzing or killing the target (assassin's
choice). While studying the victim, the assassin can undertake other
actions so long as his attention stays focused on the target and the
target does not detect the assassin or recognize the assassin as an
enemy. If the victim of such an attack fails a Fortitude save (DC 10 +
the assassin's class level + the assassin's Int modifier) against the
kill effect, she dies. If the saving throw fails against the paralysis
effect, the victim is rendered helpless and unable to act for 1d6
rounds plus 1 round per level of the assassin. If the victim&#8217;s saving
throw succeeds, the attack is just a normal sneak attack. Once the
assassin has completed the 3 rounds of study, he must make the death
attack within the next 3 rounds.</p>
    <p>If a death attack is attempted and fails (the victim makes her
save) or if the assassin does not launch the attack within 3 rounds of
completing the study, 3 new rounds of study are required before he can
attempt another death attack.</p>
    <a id="assassin-poison-use"></a><p><b>Poison Use</b>: Assassins are trained in the use of poison
and never risk accidentally poisoning themselves when applying poison
to a blade.</p>
    <p><b>Spells</b>: Beginning at 1st level, an assassin gains the
ability to cast a number of arcane spells. To cast a spell, an assassin
must have an Intelligence score of at least 10 + the spell&#8217;s level, so
an assassin with an Intelligence of 10 or lower cannot cast these
spells. Assassin bonus spells are based on Intelligence, and saving
throws against these spells have a DC of 10 + spell level + the
assassin's Intelligence bonus. When the assassin gets 0 spells per day
of a given spell level he gains only the bonus spells he would be
entitled to based on his Intelligence score for that spell level.</p>
    <p>The assassin's spell list appears below. An assassin casts
spells just as a bard does.</p>
    <p>Upon reaching 6th level, at every even-numbered level after
that (8th and 10th), an assassin can choose to learn a new spell in
place of one he already knows. The new spell's level must be the same
as that of the spell being exchanged, and it must be at least two
levels lower than the highest-level assassin spell the assassin can
cast. An assassin may swap only a single spell at any given level, and
must choose whether or not to swap the spell at the same time that he
gains new spells known for that level.</p>
    <a id="table-assassin-spells-known"></a><p><b>Table: Assassin Spells Known</b></p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th>
          </th>
          <th colSpan="4" className="center">Spells Known</th>
        </tr>
        <tr>
          <th>Level</th>
          <th>1st</th>
          <th>2nd</th>
          <th>3rd</th>
          <th>4th</th>
        </tr>
        <tr className="odd-row">
          <td>1st</td>
          <td>2<sup>1</sup></td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>2nd</td>
          <td>3</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>3rd</td>
          <td>3</td>
          <td>2<sup>1</sup></td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>4th</td>
          <td>4</td>
          <td>3</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>5th</td>
          <td>4</td>
          <td>3</td>
          <td>2<sup>1</sup></td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td>6th</td>
          <td>4</td>
          <td>4</td>
          <td>3</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td>7th</td>
          <td>4</td>
          <td>4</td>
          <td>3</td>
          <td>2<sup>1</sup></td>
        </tr>
        <tr>
          <td>8th</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>3</td>
        </tr>
        <tr className="odd-row">
          <td>9th</td>
          <td>4</td>
          <td>4</td>
          <td>4</td>
          <td>3</td>
        </tr>
        <tr>
          <td className="last-row">10th</td>
          <td className="last-row">4</td>
          <td className="last-row">4</td>
          <td className="last-row">4</td>
          <td className="last-row">4</td>
        </tr>
        <tr>
          <td colSpan="5">1 <i>Provided the assassin has sufficient
Intelligence to have a bonus spell of this level.</i></td>
        </tr>
      </tbody>
    </table>
    <p><b>Save Bonus against Poison</b>: The assassin gains a natural
saving throw bonus to all poisons gained at 2nd level that increases by
+1 for every two additional levels the assassin gains.</p>
    <p><b>Uncanny Dodge (Ex)</b>: Starting at 2nd level, an assassin
retains his Dexterity bonus to AC (if any) regardless of being caught
flat-footed or struck by an invisible attacker. (He still loses any
Dexterity bonus to AC if immobilized.)</p>
    <p>If a character gains uncanny dodge from a second class the
character automatically gains improved uncanny dodge (see below).</p>
    <p><b>Improved Uncanny Dodge (Ex)</b>: At 5th level, an assassin
can no longer be flanked, since he can react to opponents on opposite
sides of him as easily as he can react to a single attacker. This
defense denies rogues the ability to use flank attacks to sneak attack
the assassin. The exception to this defense is that a rogue at least
four levels higher than the assassin can flank him (and thus sneak
attack him).</p>
    <p>If a character gains uncanny dodge (see above) from a second
class the character automatically gains improved uncanny dodge, and the
levels from those classes stack to determine the minimum rogue level
required to flank the character.</p>
    <a id="assassin-hide-in-plain-sight"></a><p><b>Hide in Plain Sight (Su)</b>: At 8th level, an assassin can
use the Hide skill even while being observed. As long as he is within
10 feet of some sort of shadow, an assassin can hide himself from view
in the open without having anything to actually hide behind.</p>
    <p>He cannot, however, hide in his own shadow.</p>
    <a id="assassin-spell-list"></a><h6>Assassin Spell List</h6>
    <p className="initial">Assassins choose their spells from the
following list:</p>
    <p>1st Level: disguise self, detect poison, feather fall, ghost
sound, jump, obscuring mist, sleep, true strike.</p>
    <p>2nd Level: alter self, cat&#8217;s grace, darkness, fox&#8217;s cunning,
illusory script, invisibility, pass without trace, spider climb,
undetectable alignment.</p>
    <p>3rd Level: deep slumber, deeper darkness, false life, magic
circle against good, misdirection, nondetection.</p>
    <p>4th Level: clairaudience/clairvoyance, dimension door, freedom
of movement, glibness, greater invisibility, locate creature, modify
memory, poison.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      